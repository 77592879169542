// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBVjVmqBYw4fq43qAkV6stfUMr-5lgL1q0",
    authDomain: "play-atkinsonsoftware-com.firebaseapp.com",
    projectId: "play-atkinsonsoftware-com",
    storageBucket: "play-atkinsonsoftware-com.appspot.com",
    messagingSenderId: "768440825216",
    appId: "1:768440825216:web:fc5e6cac6e473399223d65"
  };

export type Firebase = {
    app: FirebaseApp
    analytics: Analytics
}

export const Init = (): Firebase => {
    let app = initializeApp(firebaseConfig);
    let analytics = getAnalytics(app);
    return {
        app,
        analytics,
    };
}